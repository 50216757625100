import React from "react"
import { Link } from "gatsby"
import RichCard from "./richCard"

const GetTheBookCard = ({ book }) =>
  <RichCard 
    url={book.frontmatter.affiliatelink} 
    title={book.frontmatter.title} 
    subtitle={book.frontmatter.author}
    description={book.frontmatter.description}
    source="amazon" 
    image={book.frontmatter.cover.childImageSharp.fixed} 
    type="book-card" />;

export default GetTheBookCard;
