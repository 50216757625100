import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import BlogPostMeta from "../components/blogPostMeta"
import Layout from "../components/blogLayout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Img from "gatsby-image"

import '../styles/blog.css'
import ArticlesPager from "../components/articlesPager"
import TOC from "../components/tableOfContent"
import PagesNav from "../components/pagesNav"
import BackToOverviewButton from "../components/backToOverviewButton"

import GetTheBookCard from "../components/cards/getTheBookCard"
// import amazonBadge from "../../content/assets/affiliate-icons/available_at_amazon_en_vertical.png"

// TODO author, genre, summary, TODO header in layout

// const GetTheBookCard = ({ book }) =>
//   <Link to={book.frontmatter.affiliatelink} style={{width: "100%"}} >
//     <figure class="blog-rich-card" data-card-type="get-book">
//       <div class="book-cover">      
//         <Img fixed={book.frontmatter.cover.childImageSharp.fixed} />
//       </div>
//       <div class="book-details">
//         <h3
//           style={{
//             marginBottom: rhythm(1 / 4),
//           }}
//         >
//           {book.frontmatter.title}
//         </h3>                
//         <div class="book-author">{book.frontmatter.author}</div>
//         <div class="book-impression">{book.frontmatter.description}</div>
//         <div class="book-get-it-source" data-source="amazon" />
//       </div>
//       </figure>
//     </Link>;


class BookNoteTemplate extends React.Component {  
  render() {
    const book = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, allPages } = this.props.pageContext

    const booksPath = `${__PATH_PREFIX__}/books`
    
    return (
      <Layout location={this.props.location} title={siteTitle} pageType={"book-note"}
        contentMaxWidth={rhythm(24)}
        rightAside={<TOC tableOfContents={book.tableOfContents} inline={false} />}
        leftAside={<PagesNav pages={allPages} thisPage={book} pathPrefix={booksPath} title={"All book notes"} />}
        below={<ArticlesPager pathPrefix={booksPath} previous={previous} next={next} />} >
        <SEO
          title={book.frontmatter.title}
          description={book.frontmatter.description || book.excerpt}
        />

        <div class="book-header">         
            <BackToOverviewButton to={booksPath} /> 
            <h1 class="blog-post-title">{book.frontmatter.title}</h1>
            <div class="book-description"><b>{book.frontmatter.description}</b></div>
            <div class="book-author">Written by <b>{book.frontmatter.author}</b></div>
            <div class="book-cover-wrapper">
              <Link to={book.frontmatter.affiliatelink} >
                <Img fixed={book.frontmatter.cover.childImageSharp.fixed} />
              </Link>
            </div>            
            <div class="book-impression">{book.frontmatter.impression}</div>          
        </div>

        <TOC tableOfContents={book.tableOfContents} inline={true} />

        <MDXRenderer>{book.body}</MDXRenderer>

        <GetTheBookCard book={book} />

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <div class="bio-wrapper">
          <Bio />
        </div>
      </Layout>
    )
  }
}

/*
node {
  excerpt(pruneLength: 160)
 */

export default BookNoteTemplate

export const pageQuery = graphql`
  query BookNoteBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      slug
      tableOfContents
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        impression
        affiliatelink
        cover {
          childImageSharp {
            fixed (width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
